import React from "react"

import { TestimonialContainer } from "./style"
import EmblaCarouselEg from "../EmblaCarouselEg/EmblaCarouselEg"
import { TestimonialData } from "./TestimonialData"
import { MainColumn } from "../MainColumn"

const Testimonial = () => {
  return (
    <TestimonialContainer>
      <MainColumn>
        <EmblaCarouselEg testimonialData={TestimonialData} />
      </MainColumn>
    </TestimonialContainer>
  )
}

export default Testimonial
