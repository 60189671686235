import styled from "styled-components"
import { device } from "breakpoints"
import Img from "gatsby-image"
import { FlexCol } from "components/FlexGrid"
import { MainColumn } from "../MainColumn"
import { FlexGrid } from "components/FlexGrid"

export const TeamFlexGrid = styled(FlexGrid)`
  padding: 0px 10px;

  @media ${device.tablet} {
    padding: 0;
  }
`

export const TeamWallWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  padding-bottom: 50px;

  @media ${device.large} {
    padding: 0;
    padding-bottom: 100px;
  }

  h2 {
    color: #ff7f41;
    font-size: 36px;
    max-width: 70%;
    margin-bottom: 40px;
    @media ${device.tablet} {
      font-size: 56px;
    }

    @media ${device.desktop} {
      margin-bottom: 40px;
      font-size: 64px;
      max-width: 60%;
    }
    @media ${device.large} {
      margin-bottom: 70px;
      font-size: 72px;
    }
  }
`

export const BorderedMainColumn = styled(MainColumn)`
  padding-top: 50px;
  opacity: 0;

  @media ${device.tablet} {
    padding-top: 70px;
  }

  @media ${device.desktop} {
    padding-top: 80px;
  }

  @media ${device.large} {
    padding-top: 90px;
  }
`

export const LogoImage = styled(Img)`
  border-radius: 50%;
  :after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    height: 100%;
    content: "";
    opacity: 0;
    /* transition: 200ms; */
    background: #ff661c;
  }
`

export const MemberInfo = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  transition: 200ms;
  width: 114px;

  @media ${device.tablet} {
    width: 206px;
  }

  h4 {
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    text-align: center;
    @media ${device.tablet} {
      line-height: 36px;
      font-size: 28px;
    }
  }

  span {
    font-size: 12px;
    font-weight: 600;
    color: #ffffff;
    line-height: 16px;
    text-align: center;
    display: inline-block;
    position: relative;

    @media ${device.tablet} {
      font-size: 16px;
      line-height: 24px;
    }
  }
  p.fun {
    margin-bottom: 3px;
    padding-bottom: 8px;
    font-size: 12px;
    font-weight: 600;
    color: #ffffff;
    line-height: 16px;
    margin-top: 4px;
    text-align: center;
    display: inline-block;
    position: relative;
    @media ${device.tablet} {
      margin-bottom: 4px;
      padding-bottom: 10px;
    }

    :before {
      content: "";
      position: absolute;
      bottom: 1px;
      left: 50%;
      transform: translateX(-50%);
      width: 60px;
      height: 2px;
      background: #ffffff;
    }
    @media ${device.tablet} {
      font-size: 16px;
      line-height: 24px;
      padding-bottom: 11px;
      :before {
        width: 100px;
      }
    }
  }

  span:nth-of-type(2) {
    /* margin-top: 20px; */
    margin-top: 0;
  }
`

export const LogoCol = styled(FlexCol)`
  position: relative;
  opacity: 0;

  a {
    :before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 5;
      height: 100%;
    }
  }

  :hover {
    ${LogoImage} {
      :after {
        opacity: 0.8;
      }
    }

    ${MemberInfo} {
      opacity: 1;
    }
  }
`
