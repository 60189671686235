import React, { useEffect } from "react"
import anime from "animejs/lib/anime.es.js"
import { FlexGrid, FlexRow, FlexCol } from "../FlexGrid"
import { AboutData } from "./aboutData"

import {
  PageTitle,
  Subtitle,
  Subtext,
  Container,
  PageHeaderFlexCol,
  SubtextBlock,
} from "./style"

const PageHeaderSection = ({ title, text1 }) => {
  const animationTimeline = () => {
    const timeline = anime.timeline()

    timeline
      .add(
        {
          targets: ".pageTitle",
          opacity: [0, 1],
          easing: "easeOutSine",
          duration: 800,
        },
        "+=100"
      )
      .add(
        {
          targets: ".subtitle",
          opacity: [0, 1],
          easing: "easeOutSine",
          duration: 440,
        },
        "-=400"
      )
      .add({
        targets: ".PageHeaderFlexCol",
        opacity: [0, 1],
        easing: "easeOutSine",
        duration: 1000,
        delay: (el, i) => 0 + 300 * i,
      })
  }
  useEffect(() => {
    animationTimeline()
  }, [])
  return (
    <Container>
      {title !== "A little about us" ? (
        <FlexGrid gap={[0, 30, 40]} rowGap={[15, 0]}>
          <FlexRow>
            <FlexCol xs={12}>
              <PageTitle className="pageTitle">{title}</PageTitle>
            </FlexCol>
            <FlexCol xs={12}>
              <Subtext className="subtitle">{text1}</Subtext>
            </FlexCol>
          </FlexRow>
        </FlexGrid>
      ) : (
        <FlexGrid gap={[0, 30, 40]} rowGap={[15, 0]}>
          <FlexRow>
            <FlexCol xs={12}>
              <PageTitle className="pageTitle">{title}</PageTitle>
            </FlexCol>
            {AboutData.map((data, index) => {
              return (
                <PageHeaderFlexCol
                  xs={12}
                  sm={6}
                  key={index}
                  className="PageHeaderFlexCol"
                >
                  <Subtitle>{data.title}</Subtitle>
                  <SubtextBlock>{data.text}</SubtextBlock>
                </PageHeaderFlexCol>
              )
            })}
          </FlexRow>
        </FlexGrid>
      )}
    </Container>
  )
}

export default PageHeaderSection
