import styled from "styled-components"
import { device } from "breakpoints"

export const TestimonialContainer = styled.div`
  padding: 30px 0;
  width: 100%;
  background: #fff;

  @media ${device.large} {
    padding-top: 90px;
    padding-bottom: 100px;
  }
`
