import React, { useState } from "react"
import { FlexRow } from "components/FlexGrid"
import { GetTeamPhotos } from "queries/GetTeamPhotos"
import anime from "animejs/lib/anime.es.js"
import { Waypoint } from "react-waypoint"
import { teamData } from "./teamData"

import {
  LogoImage,
  LogoCol,
  TeamWallWrapper,
  BorderedMainColumn,
  MemberInfo,
  TeamFlexGrid,
} from "./style"

function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[a[i], a[j]] = [a[j], a[i]]
  }
  return a
}

const TeamWall = () => {
  const [firstRun, setFirstRun] = useState(true)
  const teamPhotos = GetTeamPhotos()

  const animationTimeline = () => {
    const timeline = anime.timeline()
    timeline.add({
      targets: ".borderedMainColumn",
      opacity: [0, 1],
      easing: "easeOutSine",
      duration: 1000,
    })
    timeline.add(
      {
        targets: ".logoCol",
        opacity: [0, 1],
        easing: "easeOutSine",
        duration: 1000,
        delay: (el, i) => 0 + 100 * i,
      },
      "-=650"
    )
  }

  let team = teamPhotos.map((img) => {
    return teamData.map((member) => {
      if (img.node.name.includes(member.nameSlug)) {
        return Object.assign({ ...member, img: img })
      }
    })
  })

  team = [].concat.apply([], shuffle(team)).filter(Boolean)

  return (
    <TeamWallWrapper>
      <BorderedMainColumn className="borderedMainColumn">
        <Waypoint
          onEnter={() => firstRun && (animationTimeline(), setFirstRun(false))}
        />
        <h2>MEET THE TEAM</h2>
      </BorderedMainColumn>

      <TeamFlexGrid gap={[20, 30, 40]}>
        <FlexRow>
          {team.map((member, index) => {
            const { name, role, funRole, img } = member || {}

            if (!img.node.childImageSharp.fluid) return null
            return (
              <LogoCol key={index} xs={6} sm={4} md={3} className="logoCol">
                <LogoImage fluid={img.node.childImageSharp.fluid} />
                <MemberInfo>
                  <h4>{name}</h4>
                  {role && <p className={funRole && "fun"}>{role}</p>}
                  {funRole && <span>{funRole}</span>}
                </MemberInfo>
              </LogoCol>
            )
          })}
        </FlexRow>
      </TeamFlexGrid>
    </TeamWallWrapper>
  )
}

export default TeamWall
