import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

// import Footer from "../components/Footer/footer"
import { PageHeaderSection } from "components/PageHeaderSection"
import { Testimonial } from "components/Testimonial"
import { TeamWall } from "components/TeamWall"

const WorkPage = () => {
  return (
    <Layout>
      <SEO
        title="A little about us"
        description="We're an independent and full service agency. We shift what consumers think, feel and do with communication solutions for some of Australia’s most loved brands."
      />
      <PageHeaderSection title="A little about us" />
      <Testimonial />
      <TeamWall />
    </Layout>
  )
}

export default WorkPage
