export const TestimonialData = [
  {
    testimonial:
      "<p>“We chose to partner with Communicado from the inception of the Aussie Scrabble idea, as they shared equal parts excitement for the big idea, and detailed rigour on how to bring it to life in the most successful way.</p><p>They played their role in making this campaign the “most innovative launch we have ever done – GLOBALLY. The whole execution has been truly best in class” according to Ray Adler; Senior Director of Mattel Games, Global.</p><p>Communicado find ways to make things happen. They are not fluffy, they don’t over-promise, they push back when they don’t agree – and they get the results with seemingly minimal fuss.”</p>",
    client: "Jacinta Whitehead",
    position: "Mattel Senior Manager Marketing - Boys",
    logo: "../../images/testimonial01.png",
  },
  {
    testimonial:
      "<p>“We realised we had a huge task ahead to do justice to a brand so loved and trusted as Aeroplane Jelly (in its 90th year). We needed a communication plan that would springboard us into a year long celebration.</p><p>The team at Communicado used iconic elements from the brand’s history in a fresh and contemporary way to create a hero TVC, the standout piece across the campaign. The cross functional team at Communicado showed a great deal of passion and collaborated closely with our business.</p><p>We are very proud of what we’ve achieved and it’s provided a new benchmark to work to.”</p>",
    client: "Virginia Pullman",
    position: "Digital and Integrated Manager McCormick Foods",
    logo: "../../images/testimonial02.png",
  },
  {
    testimonial:
      "<p>“Through our long standing partnership, the team at Communicado understand our business objectives and consistently develop highly customised PR strategies for Dulux.  Communicado develops story ideas and media angles that help build our profile with our core audience.</p><p>The regular communications and updates are critical as well as the responsiveness, personalised service and commitment to helping build the Dulux brand.”</p>",
    client: "Anurita Kapur ",
    position: "Dulux Colour Communications Marketing Manager",
    logo: "../../images/testimonial03.png",
  },
  {
    testimonial:
      "<p>“Well done to you and Jamie also for helping to get the traction in the media this week.</p><p>It’s been more than I was expecting to be honest and we are all really delighted.”</p>",
    client: "Evolution Health ",
    position: "",
    logo: "../../images/testimonial04.png",
  },
  {
    testimonial:
      "<p>“There is no doubt the campaigns run by Communicado have contributed to us being number one in the natural spreads segment.</p><p>They’ve allowed us to punch above our weight in a cluttered media landscape.”</p>",
    client: "Ross Mudford",
    position: "CEO Mayver’s",
    logo: "../../images/testimonial05.png",
  },
]
