export const AboutData = [
  {
    title: "We are Communicado.",
    text:
      "We're an independent and full service agency. We shift what consumers think, feel and do with communication solutions for some of Australia’s most loved brands.",
  },
  {
    title: "We do what’s right for your brand.",
    text:
      "Our approach goes beyond the message and the medium. We focus on understanding people to craft ideas and strategies underpinned by what motivates them on the path to purchase. That means we build persuasive brand experiences that move consumers to care and convert shoppers to buy.",
  },
  {
    title: "We start where others stop.",
    text:
      "We’ll literally get right under the bonnet of your business. We’ve spent time in Bosch Auto’s mechanics workshop, created Aeroplane Jelly recipes and learned to install Rinnai hot water systems to strengthen our understanding of their business. Not only do we go beyond by ensuring our team graduates from the Dulux paint academy, but every time we receive a brief.",
  },
  {
    title: "We go beyond what’s expected.",
    text: "That’s our promise.",
  },
]
