export const teamData = [
  {
    name: "Alex Brudenell",
    nameSlug: "alex",
    funRole: "Wanderluster",
    role: "Account Director",
  },
  {
    name: "Anna Barham Kumar",
    nameSlug: "anna",
    funRole: "Musical Promethean",
    role: "Head of Content & Social",
  },
  {
    name: "Claudia Frederick",
    nameSlug: "claudia",
    funRole: "Bath House Convert",
    role: "Account Director",
  },
  {
    name: "Cristina Rudnicki",
    nameSlug: "cristina",
    funRole: "Pop Culture Specialist",
    role: "Account Director",
  },
  {
    name: "Dominique Athaide",
    nameSlug: "dominique",
    funRole: "Real Housewife of The Hills",
    role: "Senior Account Director",
  },
  {
    name: "Eliza Mielczarek",
    nameSlug: "eliza-m",
    funRole: "Resident Black Belt",
    role: "Integrated Strategy Director",
  },
  {
    name: "Joy Howard",
    nameSlug: "joy",
    funRole: "Sewist",
    role: "Finance Director",
  },
  {
    name: "Kerrie Ryan",
    nameSlug: "kerrie",
    funRole: "Ship Captain",
    role: "Managing Director",
  },
  {
    name: "Lucy Houldsworth",
    nameSlug: "lucy-h",
    funRole: "Multitasker Extraordinaire",
    role: "Director",
  },
  {
    name: "Michelle Perrott",
    nameSlug: "michelle",
    funRole: "Happiness Coordinator",
    role: "Office Manager",
  },
  {
    name: "Terence Thean",
    nameSlug: "terence",
    funRole: "Part Time Caddy",
    role: "Head of Digital",
  },
  {
    name: "Tracey Morter",
    nameSlug: "tracey",
    funRole: "The Nourisher",
    role: "Finance Manager",
  },
  {
    name: "Laura Brown",
    nameSlug: "laura",
    funRole: "Story Seeker",
    role: "Shopper Strategy Director",
  },
  {
    name: "Nicki Cole",
    nameSlug: "nicki",
    funRole: "Goalkeeper",
    role: "Integrated Client Director",
  },
  {
    name: "Angie Darras",
    nameSlug: "angie",
    funRole: "Self-care Guru",
    role: "Senior Account Executive",
  },
  {
    name: "Annie Price",
    nameSlug: "annie",
    funRole: "Thrifting Queen",
    role: "Executive Creative Director",
  },
  {
    name: "Chloe Jeffers",
    nameSlug: "chloe",
    funRole: "Lyrical Mixmaster",
    role: "Group Account Director",
  },
  {
    name: "Chris Buchanan",
    nameSlug: "chris-",
    funRole: "Insatiable Gig-goer",
    role: "Associate Creative Director",
  },
  {
    name: "Christine Hepburn",
    nameSlug: "christine",
    funRole: "Resident Cat Lady",
    role: "Senior Account Executive",
  },
  {
    name: "Dearbhla Nic Amhalghaidh",
    nameSlug: "dearbhla",
    funRole: "Recipe Sharer",
    role: "Account Director",
  },
  {
    name: "Ed Parkinson",
    nameSlug: "ed",
    funRole: "Sporty Spice",
    role: "Account Director",
  },
  {
    name: "Elif Yilmaz",
    nameSlug: "elif",
    funRole: "Latte Aficionado",
    role: "Account Manager",
  },
  {
    name: "Eliza Albie",
    nameSlug: "eliza-a",
    funRole: "Global Citizen",
    role: "Head of Art",
  },
  {
    name: "Emily Scales",
    nameSlug: "emily-s",
    funRole: "Warmth Seeker",
    role: "Account Director",
  },
  {
    name: "Fiona Mullen",
    nameSlug: "fiona",
    funRole: "Gourmet Traveller",
    role: "Head of PR & Integration Lead",
  },
  {
    name: "Jack Dinsmoor",
    nameSlug: "jack",
    funRole: "🌮",
    role: "Senior Designer",
  },
  {
    name: "Kathy Lane",
    nameSlug: "kathy",
    funRole: "Kitchen Dancer",
    role: "PR Specialist",
  },
  {
    name: "Katie Forster",
    nameSlug: "katie",
    funRole: "Queen of Online Shopping",
    role: "Senior Account Director",
  },
  {
    name: "Lucy Topham",
    nameSlug: "lucy-t",
    funRole: "Sports Fanatic",
    role: "Account Coordinator",
  },
  {
    name: "Lydia Burrows",
    nameSlug: "lydia",
    funRole: "Costa's Protégé",
    role: "Digital Creative & Social Media Manager",
  },
  {
    name: "Milly Neal",
    nameSlug: "milly",
    funRole: "Ramen Enthusiast",
    role: "Senior Account Executive",
  },
  {
    name: "Naomi Kensall",
    nameSlug: "naomi",
    funRole: "Adventure Seeker",
    role: "Senior Account Executive",
  },
  {
    name: "Sasha Tonkes",
    nameSlug: "sasha",
    funRole: "Budding Baker",
    role: "Account Executive",
  },
  {
    name: "Shiona Raj",
    nameSlug: "shiona",
    funRole: "Burncity Krumper",
    role: "Senior Account Executive",
  },
  {
    name: "Victoria Beranger",
    nameSlug: "victoria",
    funRole: "Serial Overfeeder",
    role: "Group Account Director",
  },
  {
    name: "Amy Greer",
    nameSlug: "amy",
    funRole: "Aquatic Animal",
    role: "Digital Producer",
  },
  {
    name: "Simon Fleming",
    nameSlug: "simon",
    funRole: "Amateur Dog Walker",
    role: "Associate Creative Director",
  },
  {
    name: "Yahna Fookes",
    nameSlug: "yahna",
    funRole: "Holiday Dreamer",
    role: "Senior Copywriter",
  },
  {
    name: "Emily Edwards",
    nameSlug: "emily-e",
    funRole: "Disco Diva",
    role: "Account Coordinator",
  },
  {
    name: "Robyn Cornell",
    nameSlug: "robyn",
    funRole: "The Pedlar",
    role: "Bookkeeper",
  },
  {
    name: "Daniel Hedger",
    nameSlug: "daniel",
    funRole: "Recovering Crooner",
    role: "Integrated Strategist",
  },
  {
    name: "Narayanan Subramani",
    nameSlug: "narayanan",
    funRole: "Dog Whisperer",
    role: "Account Manager",
  },
]
