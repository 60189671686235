import styled from "styled-components"
import { device } from "breakpoints"
import { FlexCol } from "../FlexGrid"

export const Container = styled.div`
  @media ${device.desktop} {
    margin-bottom: 30px;
  }
`
export const PageTitle = styled.h1`
  font-size: 60px;
  margin-bottom: 12px;
  margin-top: 1px;
  opacity: 0;

  @media ${device.tablet} {
    font-size: 76px;
  }

  @media ${device.desktop} {
    font-size: 100px;
  }

  @media ${device.large} {
    font-size: 120px;
    margin-bottom: 25px;
  }
`

export const Subtitle = styled.h3`
  font-size: 20px;
  letter-spacing: 0.33px;
  line-height: 32px;

  @media ${device.desktop} {
    font-size: 24px;
    margin-bottom: 10px;
  }
`
export const Subtext = styled.p`
  font-size: 16px;
  margin-bottom: 30px;
  opacity: 0;

  @media ${device.tablet} {
    margin-bottom: 40px;
    max-width: 89%;
  }

  @media ${device.desktop} {
    font-size: 20px;
    margin-bottom: 60px;
  }

  @media ${device.large} {
    font-size: 20px;
  }
`
export const SubtextBlock = styled(Subtext)`
  opacity: 1;
`
export const PageHeaderFlexCol = styled(FlexCol)`
  opacity: 0;
`
