import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Testimonial from "../Testimonial/Testimonial"
import {
  Item,
  TestimonialTitle,
  TestimonialSlide,
  TestimonialText,
  TestimonialName,
  TestimonialPosition,
  TestimonialLogo,
  TestimonialDetails,
  TestimonialLogoWrapper,
  TestimonialCarousel,
} from "./style"

const EmblaCarouselEg = ({ testimonialData }) => {
  const TestimonialData = useStaticQuery(
    graphql`
      query testimonialData {
        allFile(
          filter: { absolutePath: { regex: "/testimonial-images/" } }
          sort: { fields: [name], order: ASC }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(srcSetBreakpoints: [534, 768], quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `
  )

  let testimonialImages = TestimonialData.allFile.edges

  return (
    <>
      <TestimonialTitle>Our clients agree</TestimonialTitle>
      <TestimonialCarousel
        gap={40}
        fade
        loop
        align="center"
        hideButtons
        dotColor="#FF7F41"
        interval={10}
        autoPlay
      >
        {testimonialData.map((item, index) => {
          return (
            <TestimonialSlide key={index}>
              <TestimonialDetails>
                <TestimonialText
                  dangerouslySetInnerHTML={{ __html: item.testimonial }}
                />
                <TestimonialName
                  dangerouslySetInnerHTML={{ __html: item.client }}
                />
                <TestimonialPosition
                  dangerouslySetInnerHTML={{ __html: item.position }}
                />
              </TestimonialDetails>
              <TestimonialLogoWrapper>
                <TestimonialLogo
                  fluid={testimonialImages[index].node.childImageSharp.fluid}
                />
              </TestimonialLogoWrapper>
            </TestimonialSlide>
          )
        })}
      </TestimonialCarousel>
    </>
  )
}

export default EmblaCarouselEg
